import React, { useEffect } from "react";
import Details from "./components/details";
import Home from "./components/home";
import Login from "./components/login";
import PrivateRoute from "./components/PrivateRoute";
import PublicRoute from "./components/PublicRoute";
import { Container } from "react-bootstrap";
import { AuthProvider } from "./contexts/AuthContext";
import { HashRouter, Switch, Route } from "react-router-dom";
import {
  getAuth,
  setPersistence,
  signInWithEmailAndPassword,
  browserSessionPersistence,
} from "firebase/auth";
const App = () => {
  // const navigate = useNavigate();
  useEffect(() => {
    console.log("aya");
    const auth = getAuth();
    // auth.signOut();
    // auth.onAuthStateChanged((user) => {
    //   console.log("logged in ", user);
    //   // setUser(user);
    //   // if (!user) {
    //   //   navigate("/");
    //   // } else {
    //   //   navigate("/home");
    //   // }
    // });
  }, []);
  return (
    <HashRouter>
      <Switch>
        <AuthProvider>
          <Switch>
            <PrivateRoute path="/detail" component={Details} />
            <PrivateRoute exact path="/" component={Home} />
            {/* <Route path="/signup" component={Signup} /> */}
            <PublicRoute path="/login" component={Login} />
            {/* <Route path="/forgot-password" component={ForgotPassword} /> */}
          </Switch>
        </AuthProvider>
      </Switch>
    </HashRouter>
  );
};

export default App;
