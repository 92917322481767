import React, { useEffect, useState, useRef } from "react";
// import { Box, Typography, Card } from "@mui/material";
import {
  Form,
  Button,
  Card,
  InputGroup,
  Modal,
  Dropdown,
  DropdownButton,
  Row,
  Col,
  Alert,
} from "react-bootstrap";

import logo from "../images/Logo.png";
import report from "../images/report.png";
import details from "../images/details.png";
import upload from "../images/share.png";
import { getUser } from "../functions/Details";
import { useHistory } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
const Home = () => {
  const reportFile = useRef();
  const [showManual, setshowManual] = useState(false);
  const [showUpload, setshowUpload] = useState(false);
  const [errorModal, seterrorModal] = useState(false);
  const [testList, settestList] = useState([]);
  const [subtestList, setsubtestList] = useState([]);
  const [clinic, setclinic] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const [testValue, settestValue] = useState("");
  const [testName, settestName] = useState("");
  const [subtestName, setsubtestName] = useState("");
  const [testUnit, settestUnit] = useState("");
  // const [Indication, setIndication] = useState(null);
  const [limits, setlimits] = useState(null);
  const [remarks, setremarks] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const history = useHistory();
  const {
    callFunc,
    auth,
    currentUser,
    logout,
    getDocumentData,
    userProfile,
  } = useAuth();
  useEffect(() => {
    // const auth = getAuth();

    // auth.signOut();
    // console.log(auth.currentUser);
    console.log(currentUser, "user");
    console.log(userProfile, "profile");
    try {
      getDocumentData("Tests", "AllCategories")
        .then((data) => {
          console.log(data.data());
          settestList(data.data().value);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
    if (!getUser()) {
      // navigate("/");
    }
  }, []);

  const getSubTest = (value) => {
    settestName(value.target.value);
    setsubtestName("");
    settestUnit("");
    settestValue("");
    try {
      getDocumentData("Tests", value.target.value)
        .then((data) => {
          console.log(data.data());
          setsubtestList(data.data().value);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const selectTest = (val) => {
    var user = userProfile.data.data.body;
    settestValue("");

    setlimits({
      lower: val[user.gender].lowerLimit,
      upper: val[user.gender].upperLimit,
    });
    // setIndication(indication);
    setsubtestName(val.name);
    settestUnit(val.unit);
  };

  const onSignOut = async () => {
    await logout();
    // history.push("/");
  };
  const viewReports = (e) => {
    e.preventDefault();
    history.push("/detail");
  };
  const handleClose = () => {
    settestName("");
    setclinic("");
    settestValue("");
    setsubtestName("");
    settestUnit("");
    setremarks("");
    setlimits(null);
    setStartDate(new Date());
    setshowManual(false);
    setisLoading(false);
  };
  const handleCloseUpload = () => {
    settestName("");
    setclinic("");
    setremarks("");
    setStartDate(new Date());
    setshowUpload(false);
    setisLoading(false);
  };

  const onUploadReport = (val) => {
    console.log(val);
  };

  const submitManual = async () => {
    // console.log(
    //   testName,
    //   clinic,
    //   testValue,
    //   subtestName,
    //   testUnit,
    //   remarks,
    //   startDate
    // );
    if (
      testName != "" &&
      clinic != "" &&
      testValue != "" &&
      subtestName != "" &&
      testUnit != "" &&
      startDate != null
    ) {
      setisLoading(true);
      try {
        var indication = null;
        var limit = null;
        if (limits) {
          if (
            parseInt(testValue) >= parseInt(limits.lower) &&
            parseInt(testValue) <= parseInt(limits.upper)
          ) {
            indication = "0";
          } else {
            indication = "1";
          }
          limit =
            limits.lower +
            " " +
            testUnit +
            " - " +
            limits.upper +
            " " +
            testUnit;
        }

        var token = await auth.currentUser.getIdToken(true);
        callFunc(token, "SaveReportFile", {
          body: {
            clinicName: clinic,
            testName: testName,
            reportDateObject: startDate.toDateString(),
            testResults: JSON.stringify({
              name: subtestName,
              value: testValue,
              unit: testUnit,
              indication: indication,
              limits: limit,
            }),
            remarks: remarks,
          },
          patientID: currentUser.uid,
          createdBy: currentUser.uid,
          type: "manualPatient",
        })
          .then((response) => {
            if (response) {
              seterrorModal({
                visible: true,
                type: "Success",
                message: "Your report was saved successfully.",
              });
              setisLoading(false);
              setshowManual(false);
            }
          })
          .catch((error) => {
            setisLoading(false);
            seterrorModal({
              visible: true,
              type: "Error",
              message: "Error in saving report",
            });
            console.log(error);
          });
      } catch (error) {
        setisLoading(false);
        seterrorModal({
          visible: true,
          type: "Error",
          message: "Error in saving report",
        });
        console.log(error);
      }
      // setshowManual(false);
    } else {
      seterrorModal({
        visible: true,
        type: "Error",
        message: "You need to fill all mandatory fields",
      });
    }
  };
  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  const saveUpload = async () => {
    // console.log(reportFile.current);
    if (
      testName != "" &&
      clinic != "" &&
      startDate != null &&
      reportFile.current.files.length == 1
    ) {
      setisLoading(true);
      try {
        var reportBase64 = await toBase64(reportFile.current.files[0]);
        reportBase64 = reportBase64.split(",")[1];
        var report = reportFile.current.files[0];
        var token = await auth.currentUser.getIdToken(true);
        callFunc(token, "UploadReport", {
          body: {
            clinicName: clinic,
            testName: testName.toLowerCase(),
            reportDateObject: startDate.toDateString(),
            remarks: remarks == "" ? null : remarks,
          },
          fileBody: {
            file: reportBase64,
            fileName: new Date().getTime() + "_" + report.name,
            displayFileName: report.name,
            fileType: report.type,
          },
          patientID: currentUser.uid,
          createdBy: currentUser.uid,
          type: "filePatient",
        })
          .then((response) => {
            if (response) {
              seterrorModal({
                visible: true,
                type: "Success",
                message: "Your report was saved successfully.",
              });
              setisLoading(false);
              setshowUpload(false);
            }
          })
          .catch((error) => {
            setisLoading(false);
            seterrorModal({
              visible: true,
              type: "Error",
              message: "Error in saving report",
            });
            console.log(error);
          });
      } catch (error) {
        setisLoading(false);
        seterrorModal({
          visible: true,
          type: "Error",
          message: "Error in saving report",
        });
        console.log(error);
      }
    } else {
      seterrorModal({
        visible: true,
        type: "Error",
        message: "You need to fill all mandatory fields",
      });
    }
  };

  return (
    <>
      <div
        style={{
          minHeight: "100vh",
          background:
            "linear-gradient(167deg, rgb(170, 224, 249) 23.05%, rgba(197, 234, 251, 0.686) 60.73%, rgba(255, 255, 255, 0) 83.85%) center center",
        }}
      >
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              padding: 20,
            }}
          >
            <Dropdown className="profileBtn">
              <Dropdown.Toggle
                id="dropdown-autoclose-true"
                style={{ color: "black" }}
              >
                {/* <img
                  alt=""
                  src={male}
                  style={{
                    width: 50,
                    height: 50,
                  }}
                /> */}
                {userProfile != null && userProfile.data.data.body.fname}
              </Dropdown.Toggle>

              <Dropdown.Menu align={"end"}>
                <Dropdown.Item href="#" onClick={onSignOut}>
                  Sign Out
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        <div style={{ textAlign: "center" }}>
          <img
            alt=""
            src={logo}
            style={{
              width: 397,
              height: 105,
              marginTop: 35,
            }}
          />
        </div>
        <div
          className="row display-flex"
          style={{
            marginTop: 20,
            marginRight: 30,
            marginLeft: 30,
          }}
        >
          <div className="col-md-4" style={{ marginTop: 20 }}>
            <Card
              style={{
                height: "100%",
                borderRadius: 6,
                boxSizing: "border-box",
                boxShadow: "rgb(0 0 0 / 63%) 0px 4px 6px 0px",
                padding: 30,
              }}
            >
              <Card.Body>
                <img
                  style={{ height: 80, marginBottom: 10 }}
                  alt=""
                  src={report}
                />
                <h2>My reports</h2>
                <h6 style={{ marginBottom: 20 }}>
                  Get instant access to all of your reports.Your data is safe
                  and secure
                </h6>
                <a onClick={viewReports} href="#" className="btn btn-primary">
                  View reports
                </a>
              </Card.Body>
            </Card>
          </div>
          <div className="col-md-4" style={{ marginTop: 20 }}>
            <Card
              style={{
                height: "100%",
                borderRadius: 6,
                boxSizing: "border-box",
                boxShadow: "rgb(0 0 0 / 63%) 0px 4px 6px 0px",
                padding: 30,
              }}
            >
              <Card.Body>
                <img
                  style={{ height: 80, marginBottom: 10 }}
                  alt=""
                  src={details}
                />
                <h2>Add details</h2>
                <h6 style={{ marginBottom: 20 }}>
                  Add details of your new reports to access them whenever you
                  want
                </h6>
                <a
                  href="#"
                  onClick={(event) => {
                    event.preventDefault();
                    setshowManual(true);
                  }}
                  className="btn btn-primary clickBtn"
                >
                  Add Details
                </a>
              </Card.Body>
            </Card>
          </div>
          <div className="col-md-4" style={{ marginTop: 20 }}>
            <Card
              style={{
                height: "100%",
                borderRadius: 6,
                boxSizing: "border-box",
                boxShadow: "rgb(0 0 0 / 63%) 0px 4px 6px 0px",
                padding: 30,
              }}
            >
              <Card.Body>
                <img
                  style={{ height: 80, marginBottom: 10 }}
                  alt=""
                  src={upload}
                />
                <h2>Upload reports</h2>
                <h6 style={{ marginBottom: 20 }}>
                  Upload the PDF version of the report here
                </h6>
                <a
                  href="#"
                  className="btn btn-primary"
                  onClick={(event) => {
                    event.preventDefault();
                    setshowUpload(true);
                  }}
                >
                  Upload reports
                </a>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
      <Modal
        backdrop="static"
        keyboard={false}
        centered
        show={showManual}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add manual report</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label required>Name of test</Form.Label>
              <Form.Select placeholder="ddd" onChange={getSubTest}>
                <option hidden value="">
                  Select Test
                </option>
                {testList.map((value, index) => {
                  return (
                    <option key={index} value={value}>
                      {value}
                    </option>
                  );
                })}
              </Form.Select>
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Name of Medical Establishment*</Form.Label>
              <Form.Control
                type="text"
                placeholder="Mention clinic name"
                value={clinic}
                onChange={(val) => setclinic(val.target.value)}
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea2"
            >
              <Form.Label>Date of Report*</Form.Label>
              <DatePicker
                className="form-control"
                selected={startDate}
                onChange={(date) => setStartDate(date)}
              />
            </Form.Group>
            <div
              style={{
                backgroundColor: "rgba(0,0,0,0.1)",
                padding: 20,
                borderRadius: 8,
              }}
            >
              <h6>Laboratory Investigations</h6>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea3"
              >
                <InputGroup
                  className="mb-3"
                  style={{ backgroundColor: "#fff" }}
                >
                  <DropdownButton
                    variant="outline-secondary"
                    title="Select Test"
                    id="input-group-dropdown-1"
                  >
                    {subtestList.map((value, index) => {
                      return (
                        <Dropdown.Item
                          onClick={() => selectTest(value)}
                          key={index}
                        >
                          {value.name}
                        </Dropdown.Item>
                      );
                    })}
                  </DropdownButton>
                  <Form.Control
                    type="text"
                    placeholder="or input manually*"
                    value={subtestName}
                    onChange={(val) => {
                      settestUnit("");
                      settestValue("");
                      setsubtestName(val.target.value);
                      setlimits(null);
                    }}
                  />
                </InputGroup>
              </Form.Group>
              <Row className="mb-3" style={{ backgroundColor: "none" }}>
                <Form.Group as={Col} controlId="formGridEmail">
                  <Form.Label>Test Value*</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="test value"
                    value={testValue}
                    onChange={(val) => settestValue(val.target.value)}
                  />
                </Form.Group>

                <Form.Group as={Col} controlId="formGridPassword">
                  <Form.Label>Test Unit*</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="test unit"
                    value={testUnit}
                    onChange={(val) => settestUnit(val.target.value)}
                  />
                </Form.Group>
              </Row>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Remarks</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  value={remarks}
                  onChange={(val) => setremarks(val.target.value)}
                />
              </Form.Group>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            disabled={isLoading}
            onClick={!isLoading ? submitManual : null}
            variant="primary"
          >
            {isLoading ? "Uploading..." : "Save report"}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={errorModal.visible}
        onHide={() => seterrorModal({ visible: false })}
      >
        <Modal.Header closeButton>
          <Modal.Title>{errorModal.type}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{errorModal.message}</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => seterrorModal({ visible: false })}
          >
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        backdrop="static"
        keyboard={false}
        centered
        show={showUpload}
        onHide={handleCloseUpload}
      >
        <Modal.Header closeButton>
          <Modal.Title>Upload report</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label required>Name of test*</Form.Label>
              <Form.Control
                type="text"
                placeholder="Mention test name"
                value={testName}
                onChange={(val) => settestName(val.target.value)}
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Name of Medical Establishment*</Form.Label>
              <Form.Control
                type="text"
                placeholder="Mention clinic name"
                value={clinic}
                onChange={(val) => setclinic(val.target.value)}
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea2"
            >
              <Form.Label>Date of Report*</Form.Label>
              <DatePicker
                className="form-control"
                selected={startDate}
                onChange={(date) => setStartDate(date)}
              />
            </Form.Group>
            <Form.Group controlId="formFileSm" className="mb-3">
              <Form.Label>Lab Report*</Form.Label>
              <Form.Control
                ref={reportFile}
                type="file"
                size="sm"
                onChange={onUploadReport}
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Remarks</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={remarks}
                onChange={(val) => setremarks(val.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseUpload}>
            Close
          </Button>
          <Button
            disabled={isLoading}
            onClick={!isLoading ? saveUpload : null}
            variant="primary"
          >
            {isLoading ? "Uploading..." : "Save report"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Home;
