import React, { useState, useEffect, useRef } from "react";

import logo from "../images/Logo.png";
import user from "../images/Avatar.png";
import calendar from "../images/calendar.png";
import clock from "../images/clock.png";
import file from "../images/file-text.png";
import { getUser } from "../functions/Details";
import { useHistory } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import Chart from "react-apexcharts";
import ApexCharts from "apexcharts";
import {
  Spinner,
  Table,
  Tab,
  Button,
  Tabs,
  Dropdown,
  DropdownButton,
  ToggleButton,
  ButtonToolbar,
  ToggleButtonGroup,
} from "react-bootstrap";
import {
  FileEarmarkArrowDownFill,
  BarChartLineFill,
  Table as Tableicon,
} from "react-bootstrap-icons";

const Details = () => {
  const history = useHistory();
  const { currentUser, logout, callFunc, auth } = useAuth();
  const [value, setValue] = React.useState("self");
  const [result, setresult] = useState([]);
  const [resultShared, setresultShared] = useState([]);
  const [radioValue, setRadioValue] = useState(true);
  const [loading, setloading] = useState(true);
  const [loadingShared, setloadingShared] = useState(true);
  const [downloading, setdownload] = useState(null);
  const [selectionRange, setselectionRange] = useState("all");
  const [testName, settestName] = useState([]);
  const [selectedTests, setselectedTests] = useState("Select a test");
  const testChartData = useRef(null);
  const [dataPoints2, setdataPoints2] = useState({
    series: [],
    options: {
      chart: {
        id: "area-datetime",
        type: "line",
        height: 350,
        zoom: {
          autoScaleYaxis: true,
        },
      },
      stroke: {
        curve: "straight",
      },
      // annotations: {
      //   yaxis: [
      //     {
      //       y: 30,
      //       borderColor: "#999",
      //       label: {
      //         show: true,
      //         text: "Low",
      //         style: {
      //           color: "#fff",
      //           background: "#00E396",
      //         },
      //       },
      //     },
      //     {
      //       y: 35,
      //       borderColor: "#999",
      //       label: {
      //         show: true,
      //         text: "High",
      //         style: {
      //           color: "#fff",
      //           background: "#775DD0",
      //         },
      //       },
      //     },
      //   ],
      // },
      dataLabels: {
        enabled: false,
      },
      markers: {
        size: 7,
      },
      xaxis: {
        type: "datetime",
        // min: new Date("01 Mar 2012").getTime(),
      },
      tooltip: {
        x: {
          format: "dd MMM yyyy",
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.7,
          opacityTo: 0.9,
          stops: [0, 100],
        },
      },
    },

    // selection: "one_month",
  });
  const [selectedButton, setselectedButton] = useState("Table");

  const handleChange = (key) => {
    setselectedTests("Select a test");
    setValue(key);
  };
  const onSignOut = async () => {
    await logout();
    // history.push("/");
  };
  const getData = async () => {
    setloading(true);
    setloadingShared(true);
    try {
      var token = await auth.currentUser.getIdToken(true);
      callFunc(token, "getReportFile", {
        patientID: currentUser.uid,
      })
        .then((data) => {
          setresult(data.data.data);
          setloading(false);
          var aData = data.data.data;
          var cData = {};
          var aTest = [];
          aData.map((val) => {
            if (val.type == "manualPatient") {
              if (cData[val.body.testName] == undefined) {
                cData[val.body.testName] = {
                  data: [
                    [
                      new Date(val.body.reportDateObject).getTime(),
                      parseFloat(JSON.parse(val.body.testResults).value),
                    ],
                  ],
                  unit: JSON.parse(val.body.testResults).unit,
                };
              } else {
                cData[val.body.testName].data.push([
                  new Date(val.body.reportDateObject).getTime(),
                  parseFloat(JSON.parse(val.body.testResults).value),
                ]);
              }
              // cData[val.body.testName] =
              //   cData[val.body.testName] == undefined
              //     ? {
              //         data: [
              //           [
              //             new Date(val.body.reportDateObject).getTime(),
              //             parseFloat(JSON.parse(val.body.testResults).value),
              //           ],
              //         ],
              //         unit: JSON.parse(val.body.testResults).unit,
              //       }
              //     : cData[val.body.testName].data.push([
              //         new Date(val.body.reportDateObject).getTime(),
              //         parseFloat(JSON.parse(val.body.testResults).value)
              //       ]);
            }
          });
          aTest = Object.keys(cData);
          console.log(cData);
          testChartData.current = cData;
          settestName(aTest);
        })
        .catch((error) => {
          setloading(false);
          console.log(error);
        });
      var token1 = await auth.currentUser.getIdToken(true);
      callFunc(token1, "getSharedReportFile", {
        patientID: currentUser.uid,
      })
        .then((data) => {
          setresultShared(data.data.data);
          setloadingShared(false);
        })
        .catch((error) => {
          setloadingShared(false);
          console.log(error);
        });
    } catch (error) {
      // alert(JSON.stringify(error));
      console.log(error);
    }
  };
  useEffect(() => {
    getData();
  }, []);
  const getReportDoc = async (value) => {
    try {
      setdownload(value.id);
      var token = await auth.currentUser.getIdToken(true);
      callFunc(token, "downloadReportFile", {
        patientID: currentUser.uid,
        reportURL: value.body.reportURL,
      })
        .then((response) => {
          if (response) {
            var reportFile = response.data.data;
            var bodyData;
            var file =
              "data:" + reportFile.fileType + ";base64," + reportFile.file;
            if (reportFile.fileType.indexOf("image") !== -1) {
              bodyData =
                "<img width='100%' src='" + encodeURI(file) + "'></img>";
            } else {
              bodyData =
                "<iframe width='100%' height='100%' src='" +
                encodeURI(file) +
                "'></iframe>";
            }

            if (file) {
              var pdfWindow = window.open("");
              pdfWindow.document.write(bodyData);
            } else {
              alert("No Image Available!");
            }
            // saveAs(
            //   "data:" + file.fileType + ";base64," + file.file,
            //   file.displayFileName
            // );
            // window.location.href =
            //   "data:" + file.fileType + ";base64," + file.file;
          }
          // setloading(false);
          setdownload(null);
        })
        .catch((error) => {
          // setloading(false);
          setdownload(null);
          console.log(error);
        });
    } catch (error) {
      // alert(JSON.stringify(error));
      console.log(error);
    }
  };

  const updateTest = (test) => {
    setselectedTests(test.charAt(0).toUpperCase() + test.slice(1));
    ApexCharts.exec(
      "area-datetime",
      "updateSeries",
      [
        {
          name: test.charAt(0).toUpperCase() + test.slice(1),
          data: testChartData.current[test].data,
        },
      ],
      true
    );
    ApexCharts.exec(
      "area-datetime",
      "updateOptions",
      {
        yaxis: {
          title: {
            text: testChartData.current[test].unit,
            style: {
              fontSize: "20px",
            },
          },
        },
      },
      true
    );
  };
  const getOneMonthBack = (value) => {
    var d = new Date();
    if (value == 12) {
      d.setFullYear(d.getFullYear() - 1);
    } else {
      var m = d.getMonth();
      d.setMonth(d.getMonth() - value);

      // If still in same month, set date to last day of
      // previous month
      if (d.getMonth() == m) d.setDate(0);
      d.setHours(0, 0, 0, 0);
    }

    // Get the time value in milliseconds and convert to seconds
    return d;
  };

  const updateData = (timeline) => {
    var currData = dataPoints2;
    currData.selection = timeline;
    setdataPoints2(currData);
    setselectionRange(timeline);
    switch (timeline) {
      case "one_month":
        ApexCharts.exec(
          "area-datetime",
          "zoomX",
          getOneMonthBack(1).getTime(),
          new Date().getTime()
        );
        break;
      case "six_months":
        ApexCharts.exec(
          "area-datetime",
          "zoomX",
          getOneMonthBack(6).getTime(),
          new Date().getTime()
        );
        break;
      case "one_year":
        ApexCharts.exec(
          "area-datetime",
          "zoomX",
          getOneMonthBack(12).getTime(),
          new Date().getTime()
        );
        break;
      case "ytd":
        ApexCharts.exec(
          "area-datetime",
          "zoomX",
          new Date("01/01/" + new Date().getFullYear()).getTime(),
          new Date().getTime()
        );
        break;
      case "all":
        ApexCharts.exec(
          "area-datetime",
          "zoomX",
          new Date("23 Jan 2012").getTime(),
          new Date("27 Feb 2013").getTime()
        );
        break;
      default:
    }
  };
  return (
    <>
      <div
        style={{
          minHeight: "100vh",
          background:
            "linear-gradient(167deg, rgb(170, 224, 249) 23.05%, rgba(197, 234, 251, 0.686) 60.73%, rgba(255, 255, 255, 0) 83.85%) center center",
        }}
      >
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              padding: 20,
            }}
          >
            <Dropdown className="profileBtn">
              <Dropdown.Toggle
                id="dropdown-autoclose-true"
                style={{ color: "black" }}
              >
                {/* <img
                  alt=""
                  src={male}
                  style={{
                    width: 50,
                    height: 50,
                  }}
                /> */}
                {currentUser.email}
              </Dropdown.Toggle>

              <Dropdown.Menu align={"end"}>
                <Dropdown.Item href="#" onClick={onSignOut}>
                  Sign Out
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        <div style={{ textAlign: "center" }}>
          <img
            alt=""
            src={logo}
            style={{
              width: 397,
              height: 105,
              marginTop: 35,
            }}
          />
        </div>
        <div style={{ padding: 30 }}>
          <Tabs
            defaultActiveKey="self"
            id="uncontrolled-tab-example"
            className="mb-3 tab-btn"
            onSelect={handleChange}
          >
            <Tab eventKey="self" title="My Reports"></Tab>
            <Tab eventKey="shared" title="Shared with me"></Tab>
          </Tabs>
          {value == "self" && (
            <>
              {loading && (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Spinner animation="border" variant="primary" />
                  Fetching Data..
                </div>
              )}
              <div
                style={{
                  display: "flex",
                  justifyContent:
                    selectedButton == "Chart" ? "space-between" : "flex-end",
                }}
              >
                {selectedButton == "Chart" && <h2>Health Analytics</h2>}
                <ButtonToolbar>
                  <ToggleButtonGroup
                    type="radio"
                    name="options"
                    defaultValue={"Table"}
                    value={selectedButton}
                  >
                    <ToggleButton value={"Table"}>
                      <Tableicon
                        color="#FFF"
                        size={30}
                        onClick={() => {
                          setselectedTests("Select a test");
                          setselectedButton("Table");
                        }}
                      />
                    </ToggleButton>
                    <ToggleButton value={"Chart"}>
                      <BarChartLineFill
                        color="#FFF"
                        size={30}
                        onClick={() => {
                          setselectedButton("Chart");
                        }}
                      />
                    </ToggleButton>
                  </ToggleButtonGroup>
                </ButtonToolbar>
              </div>

              {selectedButton == "Table" ? (
                <Table striped hover>
                  <thead>
                    <tr>
                      <th>Test Name</th>
                      <th>Test results</th>
                      <th>Clinic Name</th>
                      <th>Date of Report</th>
                      <th>Type</th>
                    </tr>
                  </thead>
                  <tbody>
                    {result.map((value, index) => {
                      return (
                        <tr key={index} style={{ verticalAlign: "middle" }}>
                          <td>
                            {value.body.testName.charAt(0).toUpperCase() +
                              value.body.testName.slice(1)}
                          </td>
                          <td
                            style={{
                              color:
                                value.body.testResults !== undefined
                                  ? JSON.parse(value.body.testResults)
                                      .indication
                                    ? JSON.parse(value.body.testResults)
                                        .indication == 1
                                      ? "red"
                                      : "green"
                                    : "black"
                                  : "black",
                            }}
                          >
                            {value.body.testResults !== undefined
                              ? JSON.parse(value.body.testResults).value +
                                " " +
                                JSON.parse(value.body.testResults).unit
                              : ""}
                          </td>
                          <td>{value.body.clinicName}</td>
                          <td>{value.body.reportDateObject}</td>
                          <td>
                            {value.type == "manualPatient" ? (
                              ""
                            ) : (
                              <Button
                                variant="link"
                                onClick={() => getReportDoc(value)}
                              >
                                <FileEarmarkArrowDownFill
                                  color="#54D9D5"
                                  size={30}
                                />
                                {/* {downloading == value.id && ( */}
                                <Spinner
                                  animation="border"
                                  variant="primary"
                                  size="sm"
                                  style={{
                                    opacity: downloading == value.id ? 1 : 0,
                                  }}
                                />
                                {/* )} */}
                              </Button>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              ) : (
                radioValue && (
                  <div style={{ marginTop: 20 }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: 20,
                      }}
                    >
                      <DropdownButton
                        class="dropButton"
                        id="dropdown-basic-button"
                        title={
                          testName.length > 0
                            ? selectedTests
                            : "No test available"
                        }
                      >
                        {testName.length > 0 &&
                          testName.map((val, index) => {
                            return (
                              <Dropdown.Item
                                key={index}
                                onClick={() => updateTest(val)}
                              >
                                {val.charAt(0).toUpperCase() + val.slice(1)}
                              </Dropdown.Item>
                            );
                          })}
                      </DropdownButton>
                      <div class="toolbar">
                        <Button
                          variant={
                            selectionRange == "one_month"
                              ? "primary"
                              : "secondary"
                          }
                          className="rangeSelection"
                          onClick={() => updateData("one_month")}
                        >
                          1M
                        </Button>
                        <Button
                          variant={
                            selectionRange == "six_months"
                              ? "primary"
                              : "secondary"
                          }
                          className="rangeSelection"
                          onClick={() => updateData("six_months")}
                        >
                          6M
                        </Button>
                        <Button
                          variant={
                            selectionRange == "one_year"
                              ? "primary"
                              : "secondary"
                          }
                          className="rangeSelection"
                          onClick={() => updateData("one_year")}
                        >
                          1Y
                        </Button>
                        <Button
                          variant={
                            selectionRange == "ytd" ? "primary" : "secondary"
                          }
                          className="rangeSelection"
                          onClick={() => updateData("ytd")}
                        >
                          YTD
                        </Button>
                        {/* <Button
                          variant={
                            selectionRange == "all" ? "primary" : "secondary"
                          }
                          className="rangeSelection"
                          onClick={() => updateData("all")}
                        >
                          ALL
                        </Button> */}
                      </div>
                    </div>
                    <div style={{ marginLeft: 20, marginRight: 20 }}>
                      <Chart
                        options={dataPoints2.options}
                        series={dataPoints2.series}
                        type="area"
                        width="100%"
                        height={320}
                      />
                    </div>
                  </div>
                )
              )}
            </>
          )}
          {value == "shared" && (
            <>
              {loadingShared && (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Spinner animation="border" variant="primary" />
                  Fetching Data..
                </div>
              )}
              <Table striped hover>
                <thead>
                  <tr>
                    <th>Test Name</th>
                    <th>Test results</th>
                    <th>Clinic Name</th>
                    <th>Date of Report</th>
                    <th>Type</th>
                  </tr>
                </thead>
                <tbody>
                  {resultShared.map((value, index) => {
                    return (
                      <tr key={index} style={{ verticalAlign: "middle" }}>
                        <td>
                          {value.body.testName.charAt(0).toUpperCase() +
                            value.body.testName.slice(1)}
                        </td>
                        <td
                          style={{
                            color:
                              value.body.testResults !== undefined
                                ? JSON.parse(value.body.testResults).indication
                                  ? JSON.parse(value.body.testResults)
                                      .indication == 1
                                    ? "red"
                                    : "green"
                                  : "black"
                                : "black",
                          }}
                        >
                          {value.body.testResults !== undefined
                            ? JSON.parse(value.body.testResults).value +
                              " " +
                              JSON.parse(value.body.testResults).unit
                            : ""}
                        </td>
                        <td>{value.body.clinicName}</td>
                        <td>{value.body.reportDateObject}</td>
                        <td>
                          {value.type == "manualPatient" ? (
                            ""
                          ) : (
                            <Button
                              variant="link"
                              onClick={() => getReportDoc(value)}
                            >
                              <FileEarmarkArrowDownFill
                                color="#54D9D5"
                                size={30}
                              />
                            </Button>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Details;
