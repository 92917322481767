import React, { useState, useEffect, useRef } from "react";

import { Form, Button, Card, Alert } from "react-bootstrap";
import { useAuth } from "../contexts/AuthContext";
import { Link, useHistory } from "react-router-dom";
import vector from "../images/Vector.png";
import logo from "../images/Logo.png";

const Login = () => {
  // const navigate = useNavigate();
  const emailRef = useRef();
  const passwordRef = useRef();
  const { login, setProfile, callFunc, auth, currentUser } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  useEffect(() => {}, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      setError("");
      setLoading(true);
      await login(emailRef.current.value, passwordRef.current.value);
      // var token = await auth.currentUser.getIdToken(true);
      // callFunc(token, "getPatientData", {
      //   patientID: auth.currentUser.uid,
      // })
      //   .then((response) => {
      //     if (response) {
      //       var file = response.data.data;
      //       setProfile(file);
      //       setLoading(false);
      //       history.push("/");
      //     }
      //     // setloading(false);
      //   })
      //   .catch((error) => {
      //     setLoading(false);
      //     console.log(error);
      //   });
      setLoading(false);
      history.push("/");
    } catch (e) {
      setLoading(false);
      console.log(e);
      if (e.code == "auth/user-not-found") {
        setError("This user does not belong with us.");
      } else if (e.code == "auth/wrong-password") {
        setError("Invalid credentials");
      } else if (e.code == "auth/too-many-requests") {
        setError(
          "Access to this account has been temporarily disabled due to many failed login attempts"
        );
      }
    }

    // setLoading(false)
    // const auth = getAuth();
    // setPersistence(auth, browserSessionPersistence)
    //   .then(() => {
    // Existing and future Auth states are now persisted in the current
    // session only. Closing the window would clear any existing state even
    // if a user forgets to sign out.
    // ...
    // New sign-in will be persisted with session persistence.
    // return signInWithEmailAndPassword(auth, email, pass)
    //   .then((userCredential) => {
    //     // Signed in
    //     // const user = userCredential.user;
    //     // // console.log(user);
    //     // navigate("/home");
    //     // ...
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //     const errorCode = error.code;
    //     const errorMessage = error.message;
    //   });
    // })
    // .catch((error) => {
    //   // Handle Errors here.
    //   const errorCode = error.code;
    //   const errorMessage = error.message;
    // });
  };
  return (
    <div
      style={{
        background:
          "linear-gradient(167deg, rgb(170, 224, 249) 23.05%, rgba(197, 234, 251, 0.686) 60.73%, rgba(255, 255, 255, 0) 83.85%) center center",
      }}
    >
      <div
        className="row"
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          background: `url(${vector})`,
          backgroundRepeat: "no-repeat",
        }}
      >
        <div
          className="col-md-4"
          style={{
            flex: 1,
            minHeight: "100vh",
            justifyContent: "flex-end",
            alignItems: "center",
            display: "flex",
          }}
        >
          <img
            src={logo}
            alt=""
            style={{ width: "100%", paddingLeft: 30, paddingRight: 10 }}
          />
        </div>
        <div
          className="col-md-7"
          style={{
            minHeight: "100vh",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            minWidth: 500,
          }}
        >
          <Card
            style={{
              borderRadius: 6,
              boxSizing: "border-box",
              boxShadow: "rgb(0 0 0 / 63%) 0px 4px 6px 0px",
              padding: 30,
            }}
          >
            <Card.Body>
              <h2>Welcome</h2>
              <h6 style={{ marginBottom: 20 }}>
                Sign in to get the most out of your account
              </h6>
              {/* <h2 className="text-center mb-4">Log In</h2> */}
              {error && <Alert variant="danger">{error}</Alert>}
              <Form onSubmit={handleSubmit}>
                <Form.Group id="email">
                  {/* <Form.Label>Email</Form.Label> */}
                  <Form.Control
                    type="email"
                    ref={emailRef}
                    placeholder="Email"
                    required
                  />
                </Form.Group>
                <Form.Group style={{ marginTop: 20 }} id="password">
                  {/* <Form.Label>Password</Form.Label> */}
                  <Form.Control
                    type="password"
                    ref={passwordRef}
                    placeholder="Password"
                    required
                  />
                </Form.Group>

                <Button
                  disabled={loading}
                  className="w-100"
                  style={{ marginTop: 20 }}
                  type="submit"
                >
                  {loading ? "Logging in..." : "Log In"}
                </Button>
              </Form>
              {/* <div className="w-100 mt-3">
                <Link to="/forgot-password">Forgot Password?</Link>
              </div>
              <div className="w-100 mt-2">
                Need an account? <Link to="/signup">Sign Up</Link>
              </div> */}
            </Card.Body>
          </Card>
        </div>
      </div>
      {/* <Container
      className="d-flex align-items-center justify-content-center"
      style={{ minHeight: "100vh" }}
    ></Container> */}
    </div>
  );
};

export default Login;
