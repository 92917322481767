import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBhDdYdQFFV7V1fLR57pycq2_N7bL7TqzI",
  authDomain: "healthbridgeprod.firebaseapp.com",
  projectId: "healthbridgeprod",
  storageBucket: "healthbridgeprod.appspot.com",
  messagingSenderId: "13091460516",
  appId: "1:13091460516:web:91279a406a1942685cdd31",
  measurementId: "G-KS4Q8YGR65",
};

const app = initializeApp(firebaseConfig);
// const db = getFirestore(app);

export { app };
