import React, { useContext, useState, useEffect } from "react";
import { app } from "../firebase";
import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
} from "firebase/auth";

import { getFunctions, httpsCallable } from "firebase/functions";
import { getFirestore, collection, doc, getDoc } from "firebase/firestore";

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [userProfile, setuserProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const auth = getAuth();
  function signup(email, password) {
    return createUserWithEmailAndPassword(email, password);
  }

  function login(email, password) {
    return signInWithEmailAndPassword(auth, email, password);
  }

  function logout() {
    return auth.signOut();
  }

  function resetPassword(email) {
    return sendPasswordResetEmail(email);
  }

  function updateEmail(email) {
    return currentUser.updateEmail(email);
  }

  function updatePassword(password) {
    return currentUser.updatePassword(password);
  }

  function callFunc(token, fName, dataObject) {
    const functions = getFunctions(app, "asia-east2");
    const getResponse = httpsCallable(functions, fName + "?token=" + token);

    return getResponse(dataObject);
  }

  function setProfile(data) {
    setuserProfile(data);
  }

  async function getDocumentData(collectionName, docName) {
    const db = getFirestore(app);
    const docRef = doc(db, collectionName, docName);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return docSnap;
    } else {
      // doc.data() will be undefined in this case
      return false;
    }
  }

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        var token = await auth.currentUser.getIdToken(true);
        var profile = await callFunc(token, "getPatientData", {
          patientID: auth.currentUser.uid,
        });
        setuserProfile(profile);
      } else {
        setuserProfile(null);
      }

      setCurrentUser(user);
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    login,
    signup,
    logout,
    resetPassword,
    updateEmail,
    updatePassword,
    callFunc,
    getDocumentData,
    setProfile,
    userProfile,
    auth,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
